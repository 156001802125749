import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { Home } from "../home/Home";
import { Book } from "../book/Book";

export const Manager: React.FC = (): JSX.Element => {
  const [homeView, setHomeView] = useState(true);
  const [bookingView, setBookingView] = useState(false);

  const pages = (): JSX.Element => {
    return (
      <>
        {homeView ? <Home /> : <></>}
        {bookingView ? <Book /> : <></>}
      </>
    );
  };

  function showBookingView() {
    setHomeView(false);
    setBookingView(true);
  }

  function showHomeView() {
    setHomeView(true);
    setBookingView(false);
  }

  return (
    <body>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container">
          <Button
            variant="link"
            className="navbar-brand"
            onClick={showHomeView}
          >
            Fagley Carz
          </Button>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Button
                  variant="link"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={showBookingView}
                >
                  Book Online
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <header className="bg-success py-5 mb-5">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-lg-12">
              <h1 className="display-4 text-white mt-5 mb-2">01274 632006</h1>
              <p className="lead mb-5 text-white-50">Call or book online</p>
            </div>
          </div>
        </div>
      </header>
      <div className="container">{pages()}</div>

      <footer className="py-5 bg-dark">
        <div className="container">
          <p className="m-0 text-center text-white">
            01274 632006
            <br />
            booking@fagleytaxis.com
            <br />
            Fagley Road BD2 3LS 01274 632006
            <br />
            Accounts/Complaints Department 07919411033 / 07826559517
            <br />
          </p>
        </div>
      </footer>
    </body>
  );
};
