import axios, { AxiosResponse } from "axios";
import { IBook } from "../types/IBook";

export const API = axios.create({
  baseURL: "https://api.fagleycars.com/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  // headers: { token: "1234567890" },
  validateStatus: () => true,
});

export const bookingRequest = (newBooking: IBook): Promise<boolean | void> => {
  return API.post("Email", newBooking)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(function (error: any) {
      console.log("error from new member api :  " + error);
      return;
    });
};
