import React, { useRef, useState } from "react";

import fcard from "../images/fcard.jpeg";

import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { IBook } from "../../types/IBook";
import { bookingRequest } from "../../services/Service";

export const Book: React.FC = (): JSX.Element => {
  const [name, setName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [passengers, setPassengers] = useState("1 passenger");
  const [bags, setBags] = useState("No bags");
  const [pickupDate, setPickupDate] = useState(date);
  const [pickupTime, setPickupTime] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const [pickupPostcode, setPickupPostcode] = useState("");
  const [destination, setDestination] = useState("");
  const [returnJourney, setReturnJourney] = useState("Not Required");
  const [notes, setNotes] = useState("");

  const [showInvalidForm, setShowInvalidForm] = useState(false);
  const [thankyouForm, setThankyouForm] = useState(false);
  const [errorInCommunication, setErrorInCommunication] = useState(false);
  const [validated, setValidated] = useState(false);

  const formRef = useRef(null);

  function clear() {
    setName("");
    setTelephone("");
    setEmail("");
    setPassengers("");
    setBags("");
    setPickupDate("");
    setPickupTime("");
    setPickupAddress("");
    setPickupPostcode("");
    setDestination("");
    setReturnJourney("");
    setNotes("");
  }

  async function createBooking() {
    const rBooking: IBook = {
      Id: Date.now().toString(),
      Name: name,
      Telephone: telephone,
      Email: email,
      Date: date,
      Time: time,
      Passengers: passengers.toString(),
      Bags: bags.toString(),
      PickupDate: pickupDate,
      PickupTime: pickupTime,
      PickupAddress: pickupAddress,
      PickupPostcode: pickupPostcode,
      Destination: destination,
      ReturnJourney: returnJourney,
      Notes: notes,
    };

    const result = await bookingRequest(rBooking);

    if (!result) {
      setErrorInCommunication(true);
      return;
    } else {
      clear();
    }
  }

  function handleSelectPassengers(e: any) {
    setPassengers(e);
  }

  function handleSelectBags(e: any) {
    setBags(e);
  }

  function handleReturnRequired(e: any) {
    setReturnJourney(e);
  }

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setShowInvalidForm(true);
      setValidated(true);
    } else {
      createBooking();
      setThankyouForm(true);
      event.target.reset();
      setValidated(false);
    }

    event.preventDefault();
    event.stopPropagation();
  };

  function closeFormValidation() {
    setShowInvalidForm(false);
  }

  const invalidForm = (): JSX.Element => {
    return (
      <>
        <Modal show={showInvalidForm} onHide={closeFormValidation}>
          <Modal.Header closeButton>
            <Modal.Title>Missing field(s)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              Please fill in all required fields. <br />
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closeFormValidation}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function closeCompletedFormDialog() {
    setThankyouForm(false);
  }
  function closeErrorDialog() {
    setErrorInCommunication(false);
  }
  const errorCommunication = (): JSX.Element => {
    return (
      <>
        <Modal show={errorInCommunication} onHide={closeErrorDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              There is an error communicating to the server. Please call the
              base <br />
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closeFormValidation}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const completedForm = (): JSX.Element => {
    return (
      <>
        <div className="row">
          <div className="col-md-8 mb-5">
            <h2>Thank you</h2>
            <hr />
            <div>
              Your booking has been sent. Please be patient one of our advisers
              will contact you shortly.
              <br />
              Thank you
              <br />
              <br />
              <Button variant="success" onClick={() => setThankyouForm(false)}>
                Book another
              </Button>
            </div>
          </div>

          <div className="col-md-4 mb-5">
            <h2>Call Us</h2>
            <hr />
            <img src={fcard} className="card-img-top" alt="fagleycard" />
          </div>
        </div>
      </>
    );
  };

  const book = (): JSX.Element => {
    return (
      <div className="row">
        <Form
          ref={formRef}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <div className="col-md-8 mb-5">
            <h2>Online Booking System </h2>
            <hr />
            <div>
              If your Journey is in less than 24 hours please call to book
              instead:
              <span style={{ fontWeight: "bolder" }}>01274 632006</span>
              <br />
              <br />
            </div>
            <div>
              <h4>Contact details</h4>
              <br />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    autoFocus={true}
                    required
                    value={name}
                    name="test"
                    onChange={(c) => setName(c.target.value)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Phone number
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    required
                    value={telephone}
                    onChange={(c) => setTelephone(c.target.value)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    required
                    value={email}
                    onChange={(c) => setEmail(c.target.value)}
                  />
                </Col>
              </Form.Group>
              <br />
            </div>
            <div>
              <h4>Pick-up date/time</h4>
              <br />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Date
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="Date"
                    value={pickupDate}
                    onChange={(e) => setPickupDate(e.target.value)}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Time
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="Time"
                    value={pickupTime}
                    onChange={(e) => setPickupTime(e.target.value)}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Total
                </Form.Label>
                <Col sm="10">
                  <Row className="justify-content-md-center">
                    <Col md="auto">
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={passengers}
                        onSelect={handleSelectPassengers}
                        defaultValue={passengers}
                      >
                        <Dropdown.Item eventKey={"1 Passenger"}>
                          1
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={"2 passengers"}>
                          2
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={"3 passengers"}>
                          3
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={"4 passengers"}>
                          4
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={"5 passengers"}>
                          5
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={"6 passengers"}>
                          6
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={"7 passengers"}>
                          7
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={"8 passengers"}>
                          8
                        </Dropdown.Item>
                      </DropdownButton>
                    </Col>
                    <Col>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={bags}
                        onSelect={handleSelectBags}
                      >
                        <Dropdown.Item eventKey={"1 bag"}>1</Dropdown.Item>
                        <Dropdown.Item eventKey={"2 bags"}>2</Dropdown.Item>
                        <Dropdown.Item eventKey={"3 bags"}>3</Dropdown.Item>
                        <Dropdown.Item eventKey={"4 bags"}>4</Dropdown.Item>
                        <Dropdown.Item eventKey={"5 bags"}>5</Dropdown.Item>
                      </DropdownButton>
                    </Col>
                    <Col xs lg="1"></Col>
                  </Row>
                </Col>
              </Form.Group>

              <br />
            </div>
            <div>
              <h4>Pick-up</h4>
              <br />

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Address
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    value={pickupAddress}
                    onChange={(e) => setPickupAddress(e.target.value)}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Postcode
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    value={pickupPostcode}
                    onChange={(e) => setPickupPostcode(e.target.value)}
                    required
                  />
                </Col>
              </Form.Group>

              <br />
            </div>
            <div>
              <h4>Drop off</h4>
              <br />

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Destination
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    value={destination}
                    onChange={(e) => setDestination(e.target.value)}
                    required
                  />
                </Col>
              </Form.Group>

              <br />
            </div>
            <div>
              <h4>Other</h4>
              <br />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Return journey
                </Form.Label>
                <Col sm="10">
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={returnJourney}
                    onSelect={handleReturnRequired}
                  >
                    <Dropdown.Item eventKey={"Yes"}>Yes</Dropdown.Item>
                    <Dropdown.Item eventKey={"No"}>No</Dropdown.Item>
                  </DropdownButton>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Notes
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Col>
              </Form.Group>
              <br />
              Once we receive your booking request we will be in touch to
              confirm your booking
              <br />
              <br />
              <Button variant="success" type="submit">
                Submit your booking
              </Button>
              <br />
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <h2>Call Us</h2>
            <hr />
            <img src={fcard} className="card-img-top" alt="fagleycard" />
          </div>
        </Form>
      </div>
    );
  };

  return (
    <>
      <div>{!thankyouForm ? book() : <></>}</div>
      <div>{invalidForm()}</div>
      <div>{thankyouForm ? completedForm() : <></>}</div>
      <div>{errorCommunication()}</div>
    </>
  );
};
