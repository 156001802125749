import React from "react";

import airplane from "../images/airplane.jpeg";
import miniBus from "../images/miniBus.jpeg";
import car from "../images/car.jpeg";
import fcard from "../images/fcard.jpeg";
import hireCars from "../images/hireCars.jpeg";
import footy from "../images/fotty.jpg";

export const Home: React.FC = (): JSX.Element => {
  const home = (): JSX.Element => {
    return (
      <div className="row">
        <div className="col-md-8 mb-5">
          <h2>Fagley Private Hire</h2>
          <hr />
          <div>
            We provide a wide range of services to cater for all your transport
            needs, whether you need a ride to the local supermarket, to work, a
            business meeting, a special event, to university/collage, school run
            or anywhere local and across the country. <br />
            <br />
            Our modern fleet of cars and wheel chair accessible minibuses are at
            your disposal 247/365 days a year. We cover Fagley, Bradford Moor,
            Eccleshill, Undercliffe, Barkerend, Thornbury and all Surrounding
            Areas. Call 01274 632006 or Book Online Now!
            <br />
            <br />
          </div>
          <div>
            <div className="row row-cols-1 row-cols-md-3 g-4">
              <div className="col">
                <div className="card h-100">
                  <img src={car} className="card-img-top" alt="car" />
                  <div className="card-body">
                    <h5 className="card-title">
                      Corporate/School Contract Specialists
                    </h5>
                    <p className="card-text">
                      We have over 40 contracts with Bradford District Council,
                      Social Services, Local schools/Academies, Nursing Homes
                      and Local Supermarkets.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img src={miniBus} className="card-img-top" alt="miniBus" />
                  <div className="card-body">
                    <h5 className="card-title">Disability Taxi Services</h5>
                    <p className="card-text">
                      We offer dedicated vehicles for disable customers that are
                      fitted with ramps and extra belts for secure fastening of
                      wheelchairs to ensure passengers can travel safely and
                      without any problem. All our drivers are DBS checked and
                      trained to assist when requested.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img src={airplane} className="card-img-top" alt="airplane" />
                  <div className="card-body">
                    <h5 className="card-title">Airport Transfers </h5>
                    <p className="card-text">
                      We offer affordable taxi service to/from Leeds Bradford,
                      Manchester, Liverpool and all other major UK Airports.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img src={hireCars} className="card-img-top" alt="hireCars" />
                  <div className="card-body">
                    <h5 className="card-title">
                      Executive and Super Car Hire Service
                    </h5>
                    <p className="card-text">
                      We offer a full fleet of executive and super cars to suit
                      your needs, so be it a birthday, wedding, corporate event
                      or gift, let us take care of all of your car hire needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img
                    src={footy}
                    className="card-img-top"
                    alt="footballTickets"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      Football and Events Ticket Service
                    </h5>
                    <p className="card-text">
                      We have tickets for all the biggest football matches in
                      the UK and Europe, tickets for all other major sporting
                      events are also available. We can also transport you to
                      and from the venue if you require.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <h2>Contact</h2>
          <hr />
          <img src={fcard} className="card-img-top" alt="fagleycard" />
        </div>
      </div>
    );
  };

  return <div>{home()}</div>;
};
